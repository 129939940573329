import React from 'react';
import ReactDOM from 'react-dom';
import firebase from '@firebase/app';
import '@firebase/firestore';
import { FirestoreProvider } from 'react-firestore';
import './index.css'

import App from './App';

const config = {
  apiKey: 'AIzaSyCVhdeXpuphrXx1s_5N31tW7gf_LzIJ31o',
  projectId: 'nobby-homeshow-b482b',
};

firebase.initializeApp(config);

ReactDOM.render(
  <FirestoreProvider firebase={firebase} useTimestampsInSnapshots>
    <App />
  </FirestoreProvider>,
  document.getElementById('root'),
);
