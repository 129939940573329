import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import {FirestoreCollection} from 'react-firestore'




import { makeStyles } from '@material-ui/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AcessibilityIcon from '@material-ui/icons/Accessibility';
import DeleteIcon from '@material-ui/icons/Delete';

function generate(element) {
  return [0, 1, 2].map(value =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}
const renderCustomers = (data, secondary) => {
  console.log("Data",data)
  return data.map(customer => {
    return (
      <ListItem>
        <AcessibilityIcon />
        <ListItemText
          primary={customer.fullname}
          secondary={secondary ?
            <div className="secondary">
              <div className="">{customer.email}</div>
              <div className="">{customer.budget}</div>
            </div>
             : null}
        />
      </ListItem>
    );
  })
}


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: "#eeeeee",
  },
  title: {
    margin: `2rem 0 2rem 0`,
  },
  formControl: {
    color: "white",
    display: "none"
  }
}));

const RenderList = ({data}) => {
  const classes = useStyles();
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  return (
  <div className="root side-by-side">
      <Grid item className="main-wrapper">
        <Typography variant="h2" className={classes.title}>
          🏠 Homeshow Scoreboard
        </Typography>
        <Typography variant="h6" className={classes.title}>
          Total: {data.length}
        </Typography>
        <FormGroup row className="display-more">
          <FormControlLabel
            classes={classes.formControl}
            control={
              <Checkbox
                checked={secondary}
                onChange={event => setSecondary(event.target.checked)}
                value="secondary"
                color="primary"
              />
            }
            label="Show more details"
          />
        </FormGroup>
        <div className={classes.demo}>
          <List dense={dense}>
            {renderCustomers(data, secondary)}
          </List>
        </div>
    </Grid>
  </div>
);}


class App extends Component {






  render() {
    return (

        <FirestoreCollection
          path="customers"
          render={({ isLoading, data }) => {
            console.log(data);
            return isLoading ? (
              <div className="container-main">
              <Typography variant="h6" className="lodaing-thing">
                ...loading
              </Typography>
              </div>
            ) : (
              <div>

                  <RenderList data={data} />

              </div>
            );
          }}
        />


    );
  }
}

export default App;
